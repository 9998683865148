.home-page {
  height: 100vh;
  background: linear-gradient( rgba(35, 35, 35, 0.5), rgba(35, 35, 35, 0.3) ), url('./runner.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-page .MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
